import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CatCard } from "blocks-react/bedrock/components/Card/Card";
import { CatGrid } from "blocks-react/bedrock/components/Grid/Grid";
import { CatGridItem } from "blocks-react/bedrock/components/GridItem/GridItem";
import { CatHeading } from "blocks-react/bedrock/components/Heading/Heading";
import { CatIconTrendingDown } from "blocks-react/bedrock/components/Icons/TrendingDown/TrendingDown";
import { CatIconTrendingUp } from "blocks-react/bedrock/components/Icons/TrendingUp/TrendingUp";
import { CatLoadingIndicator } from "blocks-react/bedrock/components/LoadingIndicator/LoadingIndicator";
import APIError from "../APIError/APIError";
import NoDataMessage from "../DataError/NoDataMessage";
export var MetricsEnergyDurationEnum;
(function (MetricsEnergyDurationEnum) {
    MetricsEnergyDurationEnum["lastDay"] = "Last day";
    MetricsEnergyDurationEnum["lastMonth"] = "Last 30 days";
    MetricsEnergyDurationEnum["lastWeek"] = "Last 7 days";
})(MetricsEnergyDurationEnum || (MetricsEnergyDurationEnum = {}));
const Metrics = ({ energyMetricsApiStatus, energyMetricsData, metricsDate, refetchProjectEnergyMetrics }) => {
    const formatValueToTwoDecimalPoints = (value) => {
        return Number.isInteger(value) ? value.toLocaleString() : Math.round(value);
    };
    const formatValueToInteger = (value) => {
        return Math.round(value).toLocaleString();
    };
    const populateMetricsData = (metricsData) => {
        if (energyMetricsApiStatus === "error")
            return _jsx(APIError, { refreshHandler: refetchProjectEnergyMetrics });
        if (energyMetricsApiStatus === "pending")
            return (_jsx("div", { className: "flex justify-center items-center h-full min-h-[28vh] w-full", "data-test": "loading-indicator", children: _jsx(CatLoadingIndicator, {}) }));
        if (energyMetricsApiStatus === "success" && energyMetricsData.length === 0)
            return (_jsx("div", { className: "flex-auto mt-2.5 w-full", "data-test": "no-data-available", children: _jsx(NoDataMessage, { date: metricsDate }) }));
        return metricsData.map((item, index) => {
            const durationKeys = Object.keys(item.metric).filter((duration) => duration !== "currentDay");
            const nameDataTestEle = item.name.replace(/ +/g, "");
            return (_jsx(CatGridItem, { children: _jsxs(CatCard, { children: [_jsx(CatHeading, { "data-test": nameDataTestEle, variant: "label", children: item.name }), _jsx("div", { className: "mb-2 text-xl font-bold mt-4", children: _jsxs("span", { "data-test": `${nameDataTestEle}-currentDay`, children: [item.currentDay !== null && `${formatValueToTwoDecimalPoints(item.currentDay)} `, item.currentDay !== null && (_jsx("span", { className: "text-xs font-bold", "data-test": `${item.name}-current-day-kwh-label`, children: "kWh" }))] }) }), _jsxs("div", { className: "flex metric-text text-xs", children: [_jsx("div", { className: "mr-auto", children: durationKeys.map((value, index) => (_jsx("li", { className: "capitalize list-square", "data-test": `${MetricsEnergyDurationEnum[value]}-metric-energy-duration`, children: MetricsEnergyDurationEnum[value] }, index))) }), _jsxs("div", { className: "flex", children: [_jsx("div", { className: "flex flex-col items-end mr-1 pr-2 border-gray-300 border-r-2 font-bold", children: durationKeys.map((value, index) => (_jsx("span", { "data-test": `${nameDataTestEle}-${value}`, children: item.metric[value].value !== null &&
                                                    `${formatValueToTwoDecimalPoints(item.metric[value].value)} kWh` }, index))) }), _jsx("div", { className: "flex flex-col", children: durationKeys.map((value, index) => (_jsx("div", { className: "flex", children: item.metric[value].energyTrend ? (_jsxs(_Fragment, { children: [_jsx("span", { className: "mr-2", children: item.metric[value].energyTrend > 0 ? (_jsx(CatIconTrendingUp, { "data-test": `${item.name}-trending-up` })) : (_jsx(CatIconTrendingDown, { "data-test": `${item.name}-trending-down` })) }), _jsxs("span", { "data-test": `${nameDataTestEle}-${value}-energyTrend`, style: {
                                                                color: item.metric[value].energyTrend > 0 ? "red" : "green"
                                                            }, children: [formatValueToInteger(item.metric[value].energyTrend), "%"] })] })) : (_jsx("span", { className: "items-center text-white", children: "NA" })) }, index))) })] })] })] }, index) }, index));
        });
    };
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex items-center mb-4", children: [_jsx(CatHeading, { "data-test": "metrics-title", variant: "title-sm", children: "Metrics" }), _jsx("div", { className: "flex-auto ml-2.5", children: _jsx("hr", {}) })] }), _jsx("div", { "data-test": "metrics-container", children: _jsx(CatGrid, { gap: "xs", variant: "3up", children: populateMetricsData(energyMetricsData) }) })] }));
};
export default Metrics;
