export var NodeIconType;
(function (NodeIconType) {
    NodeIconType[NodeIconType["EXCAVATOR"] = 0] = "EXCAVATOR";
    NodeIconType[NodeIconType["TRACTOR"] = 1] = "TRACTOR";
    NodeIconType[NodeIconType["POWER"] = 2] = "POWER";
    NodeIconType[NodeIconType["BATTERY"] = 3] = "BATTERY";
    NodeIconType[NodeIconType["ESS"] = 4] = "ESS";
    NodeIconType[NodeIconType["WARNING"] = 5] = "WARNING";
    NodeIconType[NodeIconType["CAT950"] = 6] = "CAT950";
})(NodeIconType || (NodeIconType = {}));
export var NodeIconColor;
(function (NodeIconColor) {
    NodeIconColor["CHARGING"] = "network-green";
    NodeIconColor["DISCHARGING"] = "network-orange";
    NodeIconColor["IDLE"] = "network-blue";
    NodeIconColor["INACTIVE"] = "network-grey";
    NodeIconColor["TRANSPORTING"] = "network-pink";
})(NodeIconColor || (NodeIconColor = {}));
