import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { CatButton } from "blocks-react/bedrock/components/Button/Button";
import { CatDropdown } from "blocks-react/bedrock/components/Dropdown/Dropdown";
import { CatInputField } from "blocks-react/bedrock/components/InputField/InputField";
import { CatList } from "blocks-react/bedrock/components/List/List";
import { CatListItem } from "blocks-react/bedrock/components/ListItem/ListItem";
import { CatModal } from "blocks-react/bedrock/components/Modal/Modal";
import { CatTextPassage } from "blocks-react/bedrock/components/TextPassage/TextPassage";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
const AssetDetailsModal = (props) => {
    const { defaultAssetDetails, heading, isEditAssetName, onApplyChanges, onCloseModal, showModal, subTitle } = props;
    const ThirdPartyAssetDetailsSchema = z.object({
        assetMetadata: z.object({
            productFamily: z.object({ code: z.string().trim().min(1, "product family code is required") })
        }),
        assetName: z.string().trim().min(1, "Asset name is required"),
        assetSerialNumber: z.string().trim().min(1, "Serial number is required"),
        assetSourceId: z.string().trim().min(1, "Source id is required"),
        assetSourceType: z.string().trim().min(1, "Select any one option")
    });
    const EditAssetNameSchema = z.object({
        assetId: z.string().trim().min(1, "Asset name is required"),
        assetName: z.string().trim().min(1, "Asset name is required")
    });
    const ValidationSchema = isEditAssetName ? EditAssetNameSchema : ThirdPartyAssetDetailsSchema;
    const { control, formState: { errors, isDirty, isValid }, getValues, reset, trigger } = useForm({
        defaultValues: defaultAssetDetails,
        mode: "onBlur",
        resolver: zodResolver(ValidationSchema)
    });
    useEffect(() => {
        reset(defaultAssetDetails);
    }, [showModal]);
    return (_jsx("form", { children: _jsxs(CatModal, { "data-test": "add-asset-modal", disableBackdropClick: true, heading: heading, headingVariant: "title", isActive: showModal, onBlModalClose: () => {
                onCloseModal();
            }, size: "sm", children: [_jsxs("div", { className: "flex flex-col gap-5", children: [_jsx(CatTextPassage, { size: "sm", children: _jsx("p", { children: subTitle }) }), _jsx(Controller, { control: control, name: "assetName", render: ({ field: { onBlur, onChange, ref, value } }) => (_jsx(CatInputField, { ariaDescribedBy: "text-field-aria-1", "data-test": "asset-name", fieldId: "text-field-11", label: "Asset Name", onBlChange: onChange, onBlur: onBlur, placeholder: "Enter Asset Name", ref: ref, required: true, size: "lg", value: value, ...("assetName" in errors &&
                                    errors.assetName && {
                                    errorNote: errors.assetName.message,
                                    status: "error"
                                }) })) }), !isEditAssetName && (_jsxs("div", { className: "flex flex-col gap-5", children: [_jsx(Controller, { control: control, name: "assetSerialNumber", render: ({ field: { onBlur, onChange, ref, value } }) => (_jsx(CatInputField, { ariaDescribedBy: "text-field-aria-1", "data-test": "asset-serial-number", disabled: isEditAssetName, fieldId: "text-field-1", label: "Serial#", onBlChange: onChange, onBlur: onBlur, placeholder: "Enter Serial Number", ref: ref, required: true, size: "lg", value: value, ...("assetSerialNumber" in errors && {
                                            errorNote: errors.assetSerialNumber.message,
                                            status: "error"
                                        }) })) }), _jsx(Controller, { control: control, defaultValue: "", name: "assetSourceType", render: ({ field: { onChange, ref, value } }) => (_jsx(CatDropdown, { ariaDescribedBy: "dropdown-field-aria-1", "date-test": "asset-source-type", disabled: isEditAssetName, fieldId: "dropdown-1", label: "Asset source type", onBlDropdownClose: () => trigger("assetSourceType"), onBlSelect: (e) => {
                                            onChange(e);
                                            trigger("assetSourceType");
                                        }, placeholder: "Select an option...", ref: ref, required: true, value: value, ...("assetSourceType" in errors &&
                                            errors?.assetSourceType?.message && {
                                            errorNote: errors.assetSourceType.message,
                                            status: "error"
                                        }), children: _jsx(React.Fragment, { children: _jsxs(CatList, { spacing: "padded", children: [_jsx(CatListItem, { value: "Machine", children: "Machine" }), _jsx(CatListItem, { value: "ESS", children: "ESS" })] }) }, ".0") })) })] }))] }), _jsxs("div", { className: "flex gap-3", slot: "footer", children: [_jsx(CatButton, { disabled: !isDirty || !isValid, onClick: () => {
                                const formData = isEditAssetName
                                    ? {
                                        assetDetails: [
                                            {
                                                assetId: getValues("assetId"),
                                                assetName: getValues("assetName")
                                            }
                                        ]
                                    }
                                    : {
                                        assetMetadata: getValues("assetMetadata"),
                                        assetName: getValues("assetName"),
                                        assetSerialNumber: getValues("assetSerialNumber"),
                                        assetSourceId: getValues("assetSourceId"),
                                        assetSourceType: getValues("assetSourceType")
                                    };
                                onApplyChanges(formData);
                                reset();
                            }, variant: "primary", children: "Apply" }), _jsx(CatButton, { "data-test": "cancel-button", onClick: () => {
                                onCloseModal();
                            }, variant: "ghost", children: "Cancel" })] })] }) }));
};
export default AssetDetailsModal;
