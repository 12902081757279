import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Plotly from "plotly.js-dist-min";
import { useEffect, useState } from "react";
import { notReporting } from "../../shared/constants";
import "./Timeline.css";
const Timeline = ({ chartData, chartId }) => {
    const [tooltip, setTooltip] = useState(null);
    useEffect(() => {
        getTimelineChart();
    }, [chartData]);
    const getShapeColor = (color) => {
        const colorList = [
            { color: "#1AC69E", label: "Charging" },
            { color: "#1AC69E", label: "AC_Charging" },
            { color: "#1AC69E", label: "DC_Charging" },
            { color: "#91F2E6", label: "Trickle" },
            { color: "#808080", label: "Not Reporting" },
            { color: "#8CBAFF", label: "Idle" },
            { color: "#8CBAFF", label: "Key_Off" },
            { color: "#6852BE", label: "Transported" },
            { color: "#8E44AD", label: "Self Discharge" },
            { color: "#FB5A00", label: "Discharging" },
            { color: "#2980B9", label: "Working" },
            { color: "#2980B9", label: "Tracking" }
        ];
        let colorCode = "#808080";
        colorList.map((colorObj) => {
            if (colorObj.label === color) {
                colorCode = colorObj.color;
            }
        });
        return colorCode;
    };
    // Function to convert a time to a relative 'paper' xref coordinate
    const timeToPaperXref = (time, xRange) => {
        const xMin = new Date(xRange[0]).getTime(); // X-axis minimum in milliseconds
        const xMax = new Date(xRange[1]).getTime(); // X-axis maximum in milliseconds
        const timeMs = new Date(time).getTime(); // Time to convert to milliseconds
        // Return the value for 'xref: paper' (between 0 and 1)
        return (timeMs - xMin) / (xMax - xMin); // Calculate the relative position of time within the xRange
    };
    const getTimelineChart = () => {
        let startDate = "";
        const shapes = [];
        chartData.map((item) => {
            const { activity = "", endSoc = 0, maxSOC = 100, startSoc = 0 } = item;
            let { activityEndTimeStamp = "", activityStartTimeStamp = "" } = item;
            if (activityStartTimeStamp && activityEndTimeStamp && maxSOC) {
                const todayDate = new Date().toISOString().split("T")[0] + "T";
                if (activityStartTimeStamp.indexOf("T") === -1) {
                    activityStartTimeStamp = todayDate + activityStartTimeStamp;
                }
                if (activityEndTimeStamp.indexOf("T") === -1) {
                    activityEndTimeStamp = todayDate + activityEndTimeStamp;
                }
                if (!startDate) {
                    startDate = activityStartTimeStamp.split("T")[0];
                }
                // Convert the time to the format plotly expect
                const startTimePos = new Date(activityStartTimeStamp).getTime();
                const endTimePos = new Date(activityEndTimeStamp).getTime();
                // Convert milliseconds to plotly's 'paper' coordinate system
                const paperStart = (startTimePos - new Date(`${startDate}T00:00:00`).getTime()) / (24 * 60 * 60 * 1000); // 1 day in ms
                const paperEnd = (endTimePos - new Date(`${startDate}T00:00:00`).getTime()) / (24 * 60 * 60 * 1000); // 1 day in ms
                const minY = startSoc, maxY = endSoc;
                const shapePath = `M ${paperStart} 0 L ${paperStart} ${minY} L ${paperEnd} ${maxY} L ${paperEnd} 0 Z`;
                const coord = {
                    x0: activityStartTimeStamp.replace("T", " "),
                    x1: activityEndTimeStamp.replace("T", " "),
                    y0: minY,
                    y1: maxY
                };
                shapes.push({
                    coord,
                    fillcolor: getShapeColor(activity),
                    item,
                    line: { width: 0 },
                    path: shapePath,
                    type: "path",
                    xref: "paper",
                    yref: "y"
                });
            }
        });
        if (!startDate) {
            startDate = new Date().toISOString().slice(0, 10);
        }
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 1);
        const nextDate = endDate.toISOString().slice(0, 10);
        const data = [
            {
                hoverinfo: "none",
                line: {
                    width: 0
                },
                marker: {
                    size: 0
                },
                mode: "lines",
                type: "",
                x: [
                    `${startDate}T00:00:00`,
                    `${startDate}T04:00:00`,
                    `${startDate}T08:00:00`,
                    `${startDate}T12:00:00`,
                    `${startDate}T16:00:00`,
                    `${startDate}T20:00:00`,
                    `${nextDate}T00:00:00`
                ],
                y: ["0", "20", "40", "60", "80", "100"].map(Number)
            }
        ];
        const layout = {
            autosize: true,
            height: 60,
            hoverinfo: "skip",
            hovermode: "closest",
            margin: { b: 0, l: 0, pad: 0, r: 0, t: 0 },
            shapes,
            showlegend: false,
            xaxis: {
                dtick: 14400000, // 4 hour interval
                range: [`${startDate} 00:00:00`, `${nextDate} 00:00:00`],
                tickformat: "%H:%M",
                type: "date"
            },
            yaxis: {
                range: [0, 100],
                showgrid: false,
                showline: false,
                showticklabels: true,
                zeroline: false
            }
        };
        const config = { displayLogo: false, displayModeBar: false, responsive: true };
        Plotly.newPlot(chartId, data, layout, config);
        const timelineChart = document.getElementById(chartId);
        const timelineChartLine = document.getElementById("timeline-chart-line");
        const handleMouseMove = (event) => {
            const boundingRect = timelineChart.getBoundingClientRect();
            const xAxis = timelineChart._fullLayout.xaxis;
            const TWENTY_TWO = 22;
            const verticalLineX = event.clientX - TWENTY_TWO;
            const mouseX = event.clientX - boundingRect.left;
            const mouseY = event.clientY - boundingRect.top;
            // Convert pixel coordinates to data coordinates
            const xInDataCoords = Math.floor(xAxis.p2c(mouseX));
            // Convert data coordinates to date string
            const xInDate = xAxis.c2d(xInDataCoords);
            const hoverDate = xInDate.split(".", 1)[0];
            const xRange = layout.xaxis.range; // Get the xaxis range
            const paperX = timeToPaperXref(hoverDate, xRange); // Convert the time to 'paper' coordinate
            // Check if the mouse is within any shape
            let shapeCoordMatched = false;
            for (const shape of shapes) {
                const paperX0 = timeToPaperXref(shape.coord.x0, xRange);
                const paperX1 = timeToPaperXref(shape.coord.x1, xRange);
                if (paperX >= paperX0 && paperX <= paperX1) {
                    const tooltipX = Math.min(mouseX, window.innerWidth - 100); // Adjust position dynamically
                    const tooltipY = Math.min(mouseY, window.innerHeight - 100); // Adjust position dynamically
                    const FIVE = 5;
                    setTooltip({
                        item: { ...shape.item, hoverDate },
                        x: tooltipX + FIVE,
                        y: tooltipY
                    });
                    timelineChartLine.style.left = `${verticalLineX}px`;
                    timelineChartLine.style.display = "block";
                    if (shape && shape.item) {
                        shapeCoordMatched = true;
                    }
                    break;
                }
            }
            if (!shapeCoordMatched) {
                setTooltip(null);
            }
        };
        // Add event listener for mousemove
        timelineChart.addEventListener("mousemove", handleMouseMove);
        timelineChart.addEventListener("mouseleave", () => {
            setTooltip(null);
        });
    };
    const getDifference = (startDate, endDate) => {
        const diffInMs = Math.abs(new Date(endDate).getTime() - new Date(startDate).getTime());
        const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
        return `${hours ? hours : "00"}:${minutes ? minutes : "00"}`;
    };
    const timeFormat = (date) => {
        const hours = formatTwoDigits(date.getHours());
        const minutes = formatTwoDigits(date.getMinutes());
        const seconds = "00";
        return hours + ":" + minutes + ":" + seconds;
    };
    const formatTwoDigits = (n) => {
        return n < 10 ? "0" + n : n;
    };
    const getTooltipData = (tooltip) => {
        if (!tooltip)
            return null;
        const { activity, chargeEnergy = 0, dischargeEnergy = 0, endSoc = 0, hoverDate = "", startSoc = 0 } = tooltip;
        let { activityEndTimeStamp = "", activityStartTimeStamp = "" } = tooltip;
        const todayDate = new Date().toISOString().split("T")[0] + "T";
        if (activityStartTimeStamp.indexOf("T") === -1) {
            activityStartTimeStamp = todayDate + activityStartTimeStamp;
        }
        if (activityEndTimeStamp.indexOf("T") === -1) {
            activityEndTimeStamp = todayDate + activityEndTimeStamp;
        }
        const duration = activityStartTimeStamp && activityEndTimeStamp
            ? getDifference(activityStartTimeStamp, activityEndTimeStamp)
            : "";
        const hoverDateAndTime = new Date(hoverDate);
        const maxEnergy = Math.max(chargeEnergy, dischargeEnergy);
        const minEnergy = Math.min(chargeEnergy, dischargeEnergy);
        const energy = (maxEnergy - minEnergy).toFixed(2);
        let deltaSOC = "";
        if (startSoc && endSoc) {
            const maxDeltaSoc = Math.max(startSoc, endSoc);
            const minDeltaSoc = Math.min(startSoc, endSoc);
            deltaSOC = (maxDeltaSoc - minDeltaSoc).toFixed(2);
        }
        else {
            deltaSOC = notReporting;
        }
        return (_jsxs("div", { className: "relative block", children: [activity && _jsxs("div", { children: ["Activity: ", activity] }), _jsxs("div", { children: ["Energy: ", energy, " kWh"] }), duration ? _jsxs("div", { children: ["Duration: ", duration, " hour"] }) : null, _jsxs("div", { children: ["Delta SoC: ", `${deltaSOC} ${deltaSOC !== notReporting ? "%" : ""}`] }), hoverDateAndTime ? _jsxs("div", { children: ["Time: ", timeFormat(hoverDateAndTime)] }) : null] }));
    };
    return (_jsxs("div", { className: "relative", children: [_jsx("div", { id: chartId }), tooltip && (_jsx("div", { className: "absolute text-white bg-black/70 p-2.5 rounded-sm text-sm w-[180px] z-10 overflow-visible pointer-events-none", id: `tooltip-${chartId}`, style: { left: tooltip.x, top: tooltip.y }, children: getTooltipData(tooltip.item) }))] }));
};
export default Timeline;
