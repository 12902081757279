import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatHeading } from "blocks-react/bedrock/components/Heading/Heading";
import { CatProgressIndicator } from "blocks-react/bedrock/components/ProgressIndicator/ProgressIndicator";
import { CatProgressIndicatorItem } from "blocks-react/bedrock/components/ProgressIndicatorItem/ProgressIndicatorItem";
import React, { useState } from "react";
import CreateProjectAssetPortfolio from "../../components/CreateNewProject/CreateProjectAssetPortfolio";
import NewProjectForm from "../../components/CreateNewProject/NewProjectForm";
export var IndicatorItemsStatus;
(function (IndicatorItemsStatus) {
    IndicatorItemsStatus["active"] = "active";
    IndicatorItemsStatus["complete"] = "complete";
    IndicatorItemsStatus["disabled"] = "disabled";
    IndicatorItemsStatus["incomplete"] = "incomplete";
})(IndicatorItemsStatus || (IndicatorItemsStatus = {}));
const CreateNewProject = () => {
    const [activeStep, setActiveStep] = useState(1);
    const setActiveStepHandler = (activeStep) => {
        setActiveStep(activeStep);
    };
    const getIndicatorItemsStatus = () => {
        if (activeStep === 1) {
            return {
                assetPortfolio: IndicatorItemsStatus.disabled,
                projectInfo: IndicatorItemsStatus.active
            };
        }
        if (activeStep === 2) {
            return {
                assetPortfolio: activeStep > 2 ? IndicatorItemsStatus.complete : IndicatorItemsStatus.active,
                projectInfo: IndicatorItemsStatus.complete
            };
        }
        return {};
    };
    return (_jsxs("div", { className: "wrapper", children: [_jsx("div", { className: "p-4", children: _jsx(CatHeading, { "data-test": "create-new-project-title", level: "h5", children: "Create New Project" }) }), _jsx("div", { className: "mb-4", children: _jsx("hr", {}) }), _jsx("div", { className: "w-3/4 mx-auto", children: _jsx(CatProgressIndicator, { "data-test": "create-new-project-progress-indicator", children: _jsxs(React.Fragment, { children: [_jsx(CatProgressIndicatorItem, { fieldNote: "Optional Brief Description", label: "New Project Information", onBlProgressIndicatorItemSelect: () => {
                                    setActiveStep(1);
                                }, status: getIndicatorItemsStatus().projectInfo, stepNumber: 1, children: _jsx("div", { slot: "step-count", children: "1 of 2" }) }), _jsx(CatProgressIndicatorItem, { fieldNote: "Optional Brief Description", label: "Asset Portfolio", status: getIndicatorItemsStatus().assetPortfolio, stepNumber: 2 })] }, ".0") }) }), activeStep === 1 && _jsx(NewProjectForm, { activeStep: activeStep, setActiveStepHandler: setActiveStepHandler }), activeStep === 2 && (_jsx(CreateProjectAssetPortfolio, { activeStep: activeStep, setActiveStepHandler: setActiveStepHandler }))] }));
};
export default CreateNewProject;
