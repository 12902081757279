import { create } from "zustand";
import { logger } from "./logger";
const initialState = {
    assetDetails: [],
    isUpdateProjectDetails: false,
    projectAddress: "",
    projectId: "",
    projectName: "",
    projectOwner: "",
    updateProjectAddress: "",
    updateProjectName: "",
    updateProjectOwner: ""
};
const useCreateNewProjectStore = create()(logger((set) => ({
    ...initialState,
    setAssetDetails: (assetDetails) => {
        set(() => ({ assetDetails }));
    },
    setIsUpdateProjectDetails: (isUpdateProjectDetails) => {
        set(() => ({ isUpdateProjectDetails }));
    },
    setProjectAddress: (projectAddress) => {
        set(() => ({ projectAddress }));
    },
    setProjectId: (projectId) => {
        set(() => ({ projectId }));
    },
    setProjectName: (projectName) => {
        set(() => ({ projectName }));
    },
    setProjectOwner: (projectOwner) => {
        set(() => ({ projectOwner }));
    },
    setReset: () => {
        set(() => ({
            assetDetails: [],
            isUpdateProjectDetails: false,
            projectAddress: "",
            projectId: "",
            projectName: "",
            projectOwner: "",
            updateProjectAddress: "",
            updateProjectName: "",
            updateProjectOwner: ""
        }));
    },
    setUpdateProjectAddress: (updateProjectAddress) => {
        set(() => ({ updateProjectAddress }));
    },
    setUpdateProjectName: (updateProjectName) => {
        set(() => ({ updateProjectName }));
    },
    setUpdateProjectOwner: (updateProjectOwner) => {
        set(() => ({ updateProjectOwner }));
    }
}), "createNewProjectStore"));
export default useCreateNewProjectStore;
