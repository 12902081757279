import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatButton } from "blocks-react/bedrock/components/Button/Button";
import { CatHeading } from "blocks-react/bedrock/components/Heading/Heading";
import { CatModal } from "blocks-react/bedrock/components/Modal/Modal";
import { CatTextPassage } from "blocks-react/bedrock/components/TextPassage/TextPassage";
import { useNavigate } from "react-router-dom";
import { CaasRoute } from "../../routes";
import { useCreateProjectMutate } from "../../services/query/create-project-api.query";
import useCreateNewProjectStore from "../../store/useCreateNewProjectStore";
import useGlobalStore from "../../store/useGlobalStore";
import { Variant } from "../GlobalNotification/GlobalNotification";
const NewProjectModal = (props) => {
    const { onModalClose, showModal } = props;
    const navigate = useNavigate();
    const { isUpdateProjectDetails, projectAddress, projectName, projectOwner, setReset } = useCreateNewProjectStore((state) => ({
        isUpdateProjectDetails: state.isUpdateProjectDetails,
        projectAddress: state.projectAddress,
        projectName: state.projectName,
        projectOwner: state.projectOwner,
        setReset: state.setReset
    }));
    const { setGlobalNotification, userPartyNumber } = useGlobalStore((state) => ({
        setGlobalNotification: state.setGlobalNotification,
        userPartyNumber: state.userPartyNumber
    }));
    const { mutate } = useCreateProjectMutate(userPartyNumber);
    const onSubmitModal = (formData) => {
        mutate(formData, {
            onError: () => {
                setGlobalNotification({
                    message: `Creating new project ${projectName} has failed.Please try later`,
                    showNotification: true,
                    type: Variant.error
                });
                navigate(CaasRoute.Home);
            },
            onSuccess: () => {
                setGlobalNotification({
                    message: `New project ${projectName} created in pending state`,
                    showNotification: true,
                    type: Variant.success
                });
                navigate(CaasRoute.Home);
            }
        });
    };
    return (_jsxs(CatModal, { disableBackdropClick: true, heading: isUpdateProjectDetails ? "Are you sure" : "Save for later", headingVariant: "title", isActive: showModal, onBlModalClose: () => {
            onModalClose(false);
        }, size: "sm", children: [!isUpdateProjectDetails && (_jsxs("div", { children: [_jsx(CatTextPassage, { size: "sm", slot: "default", children: _jsxs("div", { children: [_jsx(CatHeading, { level: "h6", children: `Save ${projectName} for later` }), _jsx("p", { className: "mt-5", children: "This will save your project in the pending tab of the Projects Home page so you can complete it later." })] }) }), _jsxs("div", { className: "flex gap-3 mt-5", slot: "footer", children: [_jsx(CatButton, { "data-test": "save-modal-button", onClick: () => {
                                    onSubmitModal({
                                        owner: projectOwner,
                                        projectAddress,
                                        projectName
                                    });
                                    onModalClose(false);
                                }, variant: "primary", children: "Save" }), _jsx(CatButton, { "data-test": "discard-modal-button", onClick: () => {
                                    setReset();
                                    onModalClose(false);
                                    navigate(CaasRoute.Home);
                                }, variant: "primary", children: "Discard" }), _jsx(CatButton, { "data-test": "cancel-modal-button", onClick: () => {
                                    onModalClose(false);
                                }, variant: "ghost", children: "Cancel" })] })] })), isUpdateProjectDetails && (_jsxs("div", { children: [_jsx(CatTextPassage, { size: "sm", slot: "default", children: "Leaving this page will result in saving project in pending state. Do you wish to leave this page?" }), _jsxs("div", { className: "flex justify-end gap-3 mt-6", slot: "footer", children: [_jsx(CatButton, { onClick: () => {
                                    setReset();
                                    onModalClose(false);
                                    navigate(CaasRoute.Home);
                                }, variant: "ghost", children: "Leave page" }), _jsx(CatButton, { onClick: () => {
                                    onModalClose(false);
                                }, variant: "primary", children: "Cancel" })] })] }))] }));
};
export default NewProjectModal;
