export const assetOrderByOptions = [
    {
        name: "ASC",
        value: "Sort A to Z"
    },
    {
        name: "DESC",
        value: "Sort Z to A"
    }
];
export const socOrderByOptions = [
    {
        name: "ASC",
        value: "ASC"
    },
    {
        name: "DESC",
        value: "DESC"
    }
];
export const projectStatusOptions = [
    {
        name: "ACTIVE",
        value: "Active"
    },
    {
        name: "CLOSED",
        value: "Closed"
    },
    {
        name: "PENDING",
        value: "Pending"
    }
];
export const refetchApiInterval = 300000;
export const notReporting = "Not Reporting";
export const httpStatus = {
    ERROR_401: 401,
    ERROR_402: 402,
    ERROR_403: 403
};
export const assetSourceTypes = {
    machine: "MACHINE"
};
export const marginSpace = {
    FIFTEEN: 15,
    FIVE: 5,
    TWENTY: 20
};
