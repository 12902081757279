import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CatHeader } from "blocks-react/bedrock/components/Header/Header";
import { CatIconPerson } from "blocks-react/bedrock/components/Icons/Person";
import { CatList } from "blocks-react/bedrock/components/List/List";
import { CatListItem } from "blocks-react/bedrock/components/ListItem/ListItem";
import { CatUtilityNav } from "blocks-react/bedrock/components/UtilityNav/UtilityNav";
import { CatUtilityNavItem } from "blocks-react/bedrock/components/UtilityNavItem/UtilityNavItem";
import { useState } from "react";
import { matchPath } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import catLogo from "../../assets/CatLogo.svg";
import { CaasRoute } from "../../routes";
import useCreateNewProjectStore from "../../store/useCreateNewProjectStore";
import useGlobalStore from "../../store/useGlobalStore";
import NewProjectModal from "../CreateNewProject/NewProjectModal";
const Header = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const isRestrictedPathActive = !!matchPath(pathname, "/restricted-access");
    const [isModalActive, setIsModalActive] = useState(false);
    const { isLoggedInAsCAT } = useGlobalStore((state) => ({
        isLoggedInAsCAT: state.isLoggedInAsCAT
    }));
    const { projectAddress, projectName, projectOwner } = useCreateNewProjectStore((state) => ({
        projectAddress: state.projectAddress,
        projectName: state.projectName,
        projectOwner: state.projectOwner
    }));
    const checkNewProjectPathAndRenderModal = () => {
        const isCreateNewProjectPathActive = !!matchPath(pathname, "/create-new-project");
        if (isCreateNewProjectPathActive && projectName && projectOwner && projectAddress) {
            setIsModalActive(true);
            return true;
        }
        return false;
    };
    return (_jsxs(CatHeader, { "data-test": "cat-header", children: [_jsx("img", { className: "mx-4 cursor-pointer h-[32px] w-[60px]", "data-test": "cat-logo", onClick: () => {
                    if (!checkNewProjectPathAndRenderModal()) {
                        return !isRestrictedPathActive && navigate(CaasRoute.Home);
                    }
                    return;
                }, src: catLogo }), isLoggedInAsCAT && (_jsxs("div", { className: "ml-auto font-bold text-white", children: ["Selected Customer: ", localStorage.getItem("partyNumber")] })), _jsx(CatUtilityNav, { children: _jsxs(CatUtilityNavItem, { "data-test": "cat-utility-nav-logout", dropdown: true, children: [_jsx(CatIconPerson, { className: "cat-icon", slot: "before" }), _jsx(CatList, { className: "c-top-nav__list", spacing: "padded", children: _jsx(CatListItem, { className: "c-top-nav__list-item", "data-test": "cat-utility-logout-option", onClick: () => navigate(CaasRoute.Logout), children: "Logout" }) })] }) }), _jsx(NewProjectModal, { onModalClose: (modelState) => {
                    setIsModalActive(modelState);
                }, showModal: isModalActive })] }));
};
export default Header;
