import { useMutation } from "@tanstack/react-query";
import { updateProjectDetails } from "../api/update-project-details";
export const useUpdateProjectDetailsMutate = (partyNumber, projectId, projectStatus) => {
    return useMutation({
        gcTime: 0,
        mutationFn: (updateProjectDetailsRequest) => {
            return updateProjectDetails(partyNumber, projectId, projectStatus, updateProjectDetailsRequest);
        },
        retry: false
    });
};
