import { jsx as _jsx } from "react/jsx-runtime";
import { CatList } from "blocks-react/bedrock/components/List/List";
import { CatListItem } from "blocks-react/bedrock/components/ListItem/ListItem";
import { CatSearchForm } from "blocks-react/bedrock/components/SearchForm/SearchForm";
import React, { useState } from "react";
import useAppConfigStore from "../../store/useAppConfigStore";
const CustomerSearch = ({ partyNumber, setPartyNumber, size }) => {
    const { appConfig } = useAppConfigStore((state) => ({
        appConfig: state.appConfig
    }));
    const showCustomerList = appConfig?.release_1_1?.showMalagaProject
        ? ["Van Oord (2970049177)", "Test Customer (2969966437)", "Malaga (2969944796)"]
        : ["Van Oord (2970049177)", "Test Customer (2969966437)"]; //This needs to be replaced with API once it is available
    const [searchedResults, setSearchedResults] = useState(showCustomerList);
    function handleCustomerSearch(event) {
        const searchCustomerValue = event.detail.value?.toLowerCase() || "";
        if (searchCustomerValue) {
            const filteredResults = showCustomerList.filter((item) => item.toLowerCase().includes(searchCustomerValue));
            setPartyNumber(searchCustomerValue);
            setSearchedResults(filteredResults);
        }
        else {
            setPartyNumber("");
            setSearchedResults(showCustomerList);
        }
    }
    const handleSelectedItem = (event) => {
        setPartyNumber(event.detail.value);
    };
    return (_jsx("div", { "data-test": "customer-selection-modal", children: _jsx(CatSearchForm, { "data-test": "customer-search-form", hideSearchIcon: false, label: "list item group", onBlChange: handleCustomerSearch, onBlSelect: handleSelectedItem, openOnClick: true, placeholder: "Search Customer", size: size, value: partyNumber, children: _jsx(React.Fragment, { children: _jsx(CatList, { spacing: "padded", children: searchedResults.map((result, index) => (_jsx(CatListItem, { "data-test": "customer-selection-list-item", onBlSelect: handleSelectedItem, value: result }, index))) }) }, ".0") }) }));
};
export default CustomerSearch;
